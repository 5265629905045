import { LoadIndexType, TyreSearchPayload } from "@oaktyres/model";
import { fromUnixTime, isToday, isTomorrow } from "date-fns";
import { groupBy, uniq } from "lodash";

export type FilterSet<T> = {
  [index: string]: {
    [index: string]: (x: T, qtyRequired?: number) => boolean;
  };
};

const isCommercial = (x: TyreSearchPayload) =>
  ["C2", "C3"].includes(x.class || "") ||
  x.load.includes("/") ||
  x.commercialSuffix === "C" ||
  x.sizePrefix === "LT" ||
  (x.ply ?? 0) > 4;

const deliveryFilters: FilterSet<TyreSearchPayload> = {
  Availability: {
    Today: (x, q = 1) =>
      x.availability
        .filter(
          (x) =>
            !x.thirdParty &&
            !x.orderedStock &&
            isToday(fromUnixTime(x.dispatch)),
        )
        .map((x) => x.qtyAvailable)
        .reduce((acc, val) => acc + val, 0) >= q,
    "Next Van": (x, q = 1) =>
      x.availability
        .filter((x) => x.isNextRun)
        .map((x) => x.qtyAvailable)
        .reduce((acc, val) => val + acc, 0) >= q,
    "By Tomorrow": (x, q = 1) =>
      x.availability
        .filter(
          (x) =>
            !x.thirdParty &&
            !x.orderedStock &&
            (isToday(fromUnixTime(x.dispatch)) ||
              isTomorrow(fromUnixTime(x.dispatch))),
        )
        .map((x) => x.qtyAvailable)
        .reduce((acc, val) => val + acc, 0) >= q,
    "Imprest Stock": (x) => x.imprestQuantity > 0,
  },
};

const collectionFilters: FilterSet<TyreSearchPayload> = {
  Availability: {
    "Collect Now": (x, q = 1) =>
      x.collectionAvailability
        .filter((x) => x.availableNow)
        .map((x) => x.qtyAvailable)
        .reduce((acc, val) => acc + val, 0) >= q,
    "Collect Tomorrow": (x, q = 1) =>
      x.collectionAvailability
        .map((x) => x.qtyAvailable)
        .reduce((acc, val) => val + acc, 0) >= q,
    "Imprest Stock": (x) => x.imprestQuantity > 0,
  },
};

const filters: FilterSet<TyreSearchPayload> = {
  "Brand Tier": {
    Premium: (x) => x.brand.tier === 2,
    "Mid-Range": (x) => x.brand.tier === 1,
    Budget: (x) => x.brand.tier === 0,
    Unknown: (x) => x.brand.tier == null,
  },
  ["Load Index"]: {},
  Season: {
    Summer: (x) => x.season === "S",
    Winter: (x) => x.season === "W",
    "All-Season": (x) => x.season === "A",
  },
  "Run-Flat": {
    "Run-Flat": (x) => !!x.runFlat,
    "Not Run-Flat": (x) => !x.runFlat,
  },
  "3PMSF": {
    Yes: (x) => x.threePeaks === true,
    No: (x) => x.threePeaks !== true,
  },
  Terrain: {
    "All-Terrain": (x) => x.pattern.allTerrain === true,
    "Mud-Terrain": (x) => x.pattern.mudTerrain === true,
    "Highway Terrain": (x) =>
      x.pattern.allTerrain !== true && x.pattern.mudTerrain !== true,
  },
  "Track Use": {
    Yes: (x) => x.pattern.track === true,
    No: (x) => x.pattern.track !== true,
  },
  Trailer: {
    Yes: (x) => x.pattern.trailer === true,
    No: (x) => x.pattern.trailer === false,
    Unknown: (x) => x.pattern.trailer == null,
  },
  Directionality: {
    Directional: (x) => x.pattern.directional === true,
    Asymmetrical: (x) => x.pattern.asymmetrical === true,
    "Multi-Directional": (x) =>
      x.pattern.directional === false && x.pattern.asymmetrical === false,
    Unknown: (x) =>
      x.pattern.directional == null && x.pattern.asymmetrical == null,
  },
  Commercial: {
    Commercial: isCommercial,
    "Non-Commercial": (x) => !isCommercial(x),
  },
  "Noise Cancelling": {
    "Noise Cancelling": (x) => !!x.noiseCancelling,
    "Not Noise Cancelling": (x) => !x.noiseCancelling,
  },
  "Self-Sealing": {
    "Self-Sealing": (x) => !!x.selfSealing,
    "Not Self-Sealing": (x) => !x.selfSealing,
  },
};

export const buildFilterSetFromData = (
  data: TyreSearchPayload[],
  supplyMode: "collection" | "delivery",
): FilterSet<TyreSearchPayload> => {
  const ff = {
    ...(supplyMode === "collection" ? collectionFilters : deliveryFilters),
    ...filters,
  };

  const oeBrands = uniq(
    data.flatMap((x) => x.oes.flatMap((x) => x.brands.map((x) => x.name))),
  );

  ff.Homologations = Object.fromEntries(
    oeBrands.map((x) => [
      x,
      (f) => f.oes.some((o) => o.brands.map((x) => x.name).includes(x)),
    ]),
  );

  const loads = groupBy(data, (x) => x.load);

  const loadNames: Record<LoadIndexType, string> = {
    standard: "(Std Load)",
    extra: "(Extra Load)",
    high: "(High Load)",
    unknown: "",
  };

  ff["Load Index"] = Object.fromEntries(
    Object.values(loads).map((items) => [
      `${items[0].load} ${loadNames[items[0].loadIndexType]}`,
      (f: TyreSearchPayload) => f.load === items[0].load,
    ]),
  );

  return ff;
};
