import {
  CollectionAvailability,
  formatSpecialtyFitment,
  PriceDto,
  ProductDto,
  TyreAvailability,
  TyreSearchPayload,
} from "@oaktyres/model";
import React from "react";
import { useContext } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getProductById } from "..";
import { getTyreByStockCode, isProduct } from "../stock";

export type BasketStorage = {
  accountCode: string;
  items: {
    code: string;
    qty: number;
  }[];
};

export type BasketItemCommon = {
  code: string;
  qty: number;
  supply: "delivery" | string;
};

export type LoadingBasketItem = BasketItemCommon & {
  status: "added" | "loading" | "error";
};

export type LoadedBasketItem = BasketItemCommon & {
  status: "loaded";
  data: IStockItem;
};

export type IStockItem = {
  productId?: string;
  variantId?: string;
  stockCode: string;
  images: string[];
  name: string;
  fitment?: string;
  description: string;
  manufacturer: string;
  price: PriceDto | null;
  availability: TyreAvailability[];
  collection: CollectionAvailability[];
  rewards?: {
    points: number;
    dsp: number;
    bdf: number;
  };
  type: "tyre" | "product";
};

const wallFlags: (keyof TyreSearchPayload)[] = [
  "bsw",
  "owl",
  "rwl",
  "wsw",
  "rbl",
  "ww",
];

export const formatFullFitment = (item: TyreSearchPayload): string =>
  [
    `${item.sizePrefix || ""}${item.section}/${item.profile}`,
    `${item.construction || ""}${item.rim}${item.commercialSuffix || ""}`,
    `${item.load}${item.speed}${item.loadIndexType === "extra" ? " XL" : ""}`,
    ...item.oes.map((x) => x.mark),
    item.runFlat ? item.runFlatTech || "RUN FLAT" : null,
    item.noiseCancellingTech,
    item.selfSealingTech,
    ...wallFlags.map((x) => (item[x] ? x.toUpperCase() : null)),
  ]
    .filter(Boolean)
    .join(" ");

export const tyreToStockItem = (tyre: TyreSearchPayload): IStockItem => {
  return {
    stockCode: tyre.stockCode,
    images: tyre.pattern?.images ?? [],
    name: tyre.pattern?.name ?? "",
    fitment: tyre.isSpecialty
      ? formatSpecialtyFitment(tyre.specialtyFitment!)
      : formatFullFitment(tyre),
    description: "",
    manufacturer: tyre.brand.crossReference,
    price: tyre.price ?? null,
    availability: tyre.availability,
    type: "tyre",
    rewards: tyre.rewards,
    collection: tyre.collectionAvailability,
  };
};

export const productToStockItem = (
  product: ProductDto,
  stockCode: string,
): IStockItem => {
  const v = product.variants.find((x) => x.stockCode === stockCode)!;

  return {
    productId: product.id,
    variantId: v.id,
    stockCode: stockCode,
    images: [...v.images, ...product.images],
    name: product.name,
    description: product.description,
    manufacturer: v.manufacturer,
    price: v.price ?? null,
    availability: v.availability ?? [],
    type: "product",
    collection: [],
  };
};

export const genericProductToStockItem = (
  item: ProductDto | TyreSearchPayload,
  stockCode: string,
): IStockItem => {
  if (isProduct(item)) {
    return productToStockItem(item, stockCode);
  } else {
    return tyreToStockItem(item);
  }
};

export type BasketItem = LoadingBasketItem | LoadedBasketItem;

export type UseBasketReturnType = {
  items: BasketItem[];
  accountCode: string | null;
  clearBasket: () => void;
  addToBasket: (code: string, qty: number, accountCode: string) => boolean;
  updateItem: (code: string, qty: number) => void;
};

const BasketContext = React.createContext<UseBasketReturnType>(null!);

const fromStorage = () => {
  const s: BasketStorage = JSON.parse(
    window.localStorage.getItem("chase-basket") ?? "null",
  ) ?? { accountCode: null, items: [] };

  return {
    accountCode: s?.accountCode,
    items: (s?.items ?? []).map<BasketItem>((x) => ({
      supply: "delivery",
      ...x,
      status: "added",
    })),
  };
};

export function BasketProvider({ children }: { children: React.ReactNode }) {
  const store = fromStorage();
  const [accountCode, setAccountCode] = useState<string | null>(
    store.accountCode,
  );
  const [items, setItems] = useState<BasketItem[]>(store.items);

  useEffect(() => {
    const s = {
      accountCode: accountCode,
      items: items.map((x) => ({
        code: x.code,
        qty: x.qty,
      })),
    };

    window.localStorage.setItem("chase-basket", JSON.stringify(s));
  }, [items, accountCode]);

  useEffect(() => {
    items
      .filter((x) => x.status === "added")
      .forEach(async (x) => {
        setItems((old) =>
          old.map((o) =>
            o.code === x.code
              ? {
                  ...o,
                  status: "loading",
                }
              : o,
          ),
        );

        let item: IStockItem | null = null;

        try {
          item = tyreToStockItem(await getTyreByStockCode(x.code, accountCode));
        } catch {}

        if (item == null) {
          item = productToStockItem(
            await getProductById(x.code, accountCode ?? undefined),
            x.code,
          );
        }

        if (item == null) {
          setItems((old) =>
            old.map((o) =>
              o.code === x.code
                ? {
                    ...o,
                    status: "error",
                  }
                : o,
            ),
          );
        } else {
          setItems((old) =>
            old.map((o) =>
              o.code === x.code
                ? {
                    ...o,
                    status: "loaded",
                    data: item!,
                  }
                : o,
            ),
          );
        }
      });
  }, [items, accountCode]);

  const clearBasket = useCallback(() => {
    setItems([]);
  }, []);

  const addToBasket = useCallback(
    (code: string, qty: number, itemAccountCode: string) => {
      if (accountCode != null && itemAccountCode !== accountCode) {
        if (!window.confirm("Start a new basket with new account code?")) {
          return false;
        }
        setItems([]);
      }

      setAccountCode(itemAccountCode);

      setItems((old) => {
        if (old.some((x) => x.code === code)) {
          return old.map((x) =>
            x.code === code
              ? {
                  ...x,
                  qty: x.qty + qty,
                }
              : x,
          );
        } else {
          return [
            ...old,
            {
              code: code,
              qty: qty,
              supply: "delivery",
              status: "added",
            },
          ];
        }
      });
      return true;
    },
    [accountCode],
  );

  const updateItem = useCallback((code: string, qty: number) => {
    setItems((old) =>
      old
        .map((x) => ({
          ...x,
          qty: x.code === code ? qty : x.qty,
        }))
        .filter((x) => x.qty > 0),
    );
  }, []);

  const res = {
    items,
    clearBasket,
    addToBasket,
    accountCode,
    updateItem,
  };

  return (
    <BasketContext.Provider value={res}>{children}</BasketContext.Provider>
  );
}

export const useBasket = () => useContext(BasketContext);
