import {
  CollectionAvailability,
  formatSpecialtyFitment,
  formatTimeOfDay,
  getClosingTime,
  getDayName,
  getNextOpenDay,
  getOpeningTime,
  isDayTomorrow,
  SpecialtyTreadStyles,
  StockHistoryEntryDto,
  TyreAvailability,
  TyreSearchPayload,
} from "@oaktyres/model";
import {
  BasketItem,
  formatFullFitment,
  useSupplyOptions,
  useTyreByStockCode,
} from "@oaktyres/queries";
import { format } from "date-fns";
import fromUnixTime from "date-fns/esm/fp/fromUnixTime/index.js";
import isToday from "date-fns/isToday";
import { groupBy, startCase, sum } from "lodash";
import { lighten } from "polished";
import React, { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import {
  FaCartPlus,
  FaCheck,
  FaChevronLeft,
  FaChevronRight,
  FaExclamationTriangle,
  FaShoppingCart,
  FaTimes,
  FaTruck,
} from "react-icons/fa";
import styled from "styled-components";
import { useAuth } from "../../auth";
import threePeaksSrc from "../../img/3pmsf.svg";
import allSeasonSrc from "../../img/allseason.svg";
import summerSrc from "../../img/summer.svg";
import winterSrc from "../../img/winter.svg";
import { Alert } from "../Alert";
import Box from "../Box";
import Button from "../Button";
import Circle from "../Circle";
import Flex from "../Flex";
import FormHeading from "../FormHeading";
import HelpPopover from "../HelpPopOver";
import IconButton from "../IconButton";
import Label from "../Label";
import Loader from "../Loader";
import ManufacturerLabel from "../ManufacturerLabel";
import { MiniTable } from "../MiniTable";
import Modal from "../Modal";
import Panel, { PanelHeader } from "../Panel";
import Select from "../Select";
import Text from "../Text";
import { useToasts } from "../ToastProvider";
import { OrderHistoryTable } from "./OrderHistoryTable";
import { TyreImageWidget } from "./TyreImageWidget";
import { TyreClassLabel } from "./TyreNoiseLabel";

const seasonSrc = {
  W: winterSrc,
  S: summerSrc,
  A: allSeasonSrc,
};

const seasons = {
  W: "Winter",
  S: "Summer",
  A: "All-Season",
};

const seasonColors = {
  W: "#57A2B6",
  S: "#DCCF1A",
  A: "#57B66E",
};

const fuelEfficiencyColors: Record<string, string> = {
  A: "#00A651",
  B: "#9ACA3C",
  C: "#FFF200",
  D: "#FDB913",
  E: "#ED1C24",
};

const wetGripColors: Record<string, string> = {
  A: "#0066B3",
  B: "#0080C6",
  C: "#4BA6DD",
  D: "#75BEE9",
  E: "#ABE1FA",
};

const ThreePeaksImage = styled.img`
  height: 24px;
`;

ThreePeaksImage.defaultProps = {
  src: threePeaksSrc,
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const formatCutoff = (cutoff: number) => {
  const date = fromUnixTime(cutoff);
  const dayName = isToday(date) ? "today" : days[date.getDay()];

  return `${format(date, "HH:mm")} ${dayName}`;
};

const flags: [
  string,
  keyof TyreSearchPayload | keyof TyreSearchPayload["pattern"],
][] = [
  ["Asymmetrical", "asymmetrical"],
  ["Run Flat", "runFlat"],
  ["All Terrain", "allTerrain"],
  ["Mud Terrain", "mudTerrain"],
  ["Highway Terrain", "highwayTerrain"],
  ["UHP", "uhp"],
  ["Light Commercial", "lightCommercial"],
  ["Trailer", "trailer"],
  ["Rim Protector", "rimProtector"],
  ["Self-Sealing", "selfSealing"],
  ["Vintage", "vintage"],
  ["EV Specific", "ev"],
  ["Noise Cancelling", "noiseCancelling"],
];

const PatternLogo = styled.img`
  max-width: 250px;
  max-height: 50px;
  margin-bottom: 6px;
`;

const StatTable = styled.table`
  margin-bottom: 12px;

  td:first-child {
    padding-right: 24px;
    font-weight: 600;
    width: 0;
    white-space: nowrap;
  }

  td:last-child {
    width: 100%;
    color: #111;
  }
`;

export type StockCodeSet = {
  codes: string[];
  index: number;
};

export type TyreModalProps = {
  stockCode: string | StockCodeSet;
  accountCode?: string | null;
  onStockCodeChange?: (newCode: StockCodeSet) => void;
  showHistory?: "no" | "yes" | "locked";
  onClose: () => void;
  basketItems: BasketItem[];
  onAddToBasket: (count: number, supply: "delivery" | string) => boolean;
  hidePrices?: boolean;
  onOrderHistorySelect?: (item: StockHistoryEntryDto) => void;
  defaultSupply?: string;
};

type GroupAvailability = TyreAvailability & {
  taken: number;
};

type GroupCollection = CollectionAvailability & { taken: number };

const groupCollections = (
  input: CollectionAvailability[],
  reservedCount: number,
): GroupCollection[] => {
  const grouped = groupBy(input, (x) =>
    [x.source.id, x.availableNow, x.price].join(","),
  );

  const result: GroupCollection[] = [];

  for (const x of Object.values(grouped)) {
    let q = sum(x.map((x) => x.qtyAvailable));

    const canSub = Math.min(q, reservedCount);

    reservedCount -= canSub;

    const val = {
      ...x[0],
      qtyAvailable: q,
      taken: canSub,
    };
    result.push(val);
  }

  return result;
};

const groupAvailabilities = (
  input: TyreAvailability[],
  reservedCount: number,
): GroupAvailability[] => {
  const grouped = groupBy(input, (x) =>
    [x.cutoff, x.price, x.dispatch].join(","),
  );

  const result: GroupAvailability[] = [];

  for (const x of Object.values(grouped)) {
    let q = sum(x.map((x) => x.qtyAvailable));

    const canSub = Math.min(q, reservedCount);

    reservedCount -= canSub;

    const val = {
      ...x[0],
      qtyAvailable: q,
      taken: canSub,
    };
    result.push(val);
  }

  return result;
};

type AvailabilityDisplayProps = {
  availability: TyreAvailability | CollectionAvailability;
  hidePrices?: boolean;
  taken: number;
};

const isCollection = (
  x: TyreAvailability | CollectionAvailability,
): x is CollectionAvailability => "availableNow" in x;

const NoAvailabilities = () => {
  return (
    <Panel borderColor={"warning"} p={2} mb={1} opacity={0.5}>
      <Flex alignItems="center">
        <Circle icon={FaExclamationTriangle} color="warning" mr={2} />
        <Text color="warning" fontWeight={600}>
          No availability
        </Text>
      </Flex>
    </Panel>
  );
};

type AvailabilityTitleProps = {
  availability: TyreAvailability | CollectionAvailability;
};

const AvailabilityTitle = ({ availability }: AvailabilityTitleProps) => {
  let text = "";
  let mainTitle = "";

  const coll = isCollection(availability);

  const label = !coll && availability.isNextRun ? "Next Van" : false;

  if (coll) {
    if (availability.availableNow) {
      text = `Ready for collection until ${formatTimeOfDay(getClosingTime(availability.source)) ?? "closing"}`;
      mainTitle = "Available Now";
    } else {
      const nextOpenDay = getNextOpenDay(availability.source);

      text = "Ready at next opening time";
      mainTitle = "Ready Soon";

      if (nextOpenDay != null) {
        const dayText = isDayTomorrow(nextOpenDay)
          ? "tomorrow"
          : getDayName(nextOpenDay);
        const openingTime = getOpeningTime(availability.source, nextOpenDay);

        text = `Ready by ${formatTimeOfDay(openingTime)} ${dayText}`;
        mainTitle = `Ready ${startCase(dayText)}`;
      }
    }
  } else if (availability.orderedStock) {
    text = " ";
    mainTitle = "Due within 5 days";
  } else {
    text = `Order by ${formatCutoff(availability.cutoff)}`;
    mainTitle = formatCutoff(availability.dispatch);
  }

  return (
    <>
      <Flex alignItems={"center"}>
        <Text fontWeight={600} lineHeight={1}>
          {mainTitle}
        </Text>
        {label && (
          <Label labelSize="tiny" color="positive" ml={1} icon={FaTruck}>
            {label}
          </Label>
        )}
      </Flex>
      <Text color="grey2" fontSize={1} mb={1}>
        {text}
      </Text>
    </>
  );
};

const AvailabilityDisplay = ({
  availability,
  hidePrices,
  taken,
}: AvailabilityDisplayProps) => {
  const coll = isCollection(availability);

  let bc = "info";

  if (
    (coll && availability.availableNow) ||
    (!coll && availability.isNextRun)
  ) {
    bc = "positive";
  }

  return (
    <Panel borderColor={bc} p={2} mb={1}>
      <Flex alignItems="flex-start" justifyContent="space-between">
        <div>
          <AvailabilityTitle availability={availability} />
        </div>
        {coll ? null : availability.expectedArrival ? (
          <HelpPopover
            content={`Delivery expected between ${availability.expectedArrival[0]} and ${availability.expectedArrival[1]}`}
          />
        ) : availability.orderedStock ? (
          <HelpPopover content="Depends on incoming stock" />
        ) : availability.thirdParty ? (
          <HelpPopover content="Depends on 3rd party supplier" />
        ) : null}
      </Flex>
      <Flex justifyContent="space-between">
        {taken > 0 && `${taken} / `}
        {availability.qtyAvailable} Available
        <Text fontSize={2}>
          {hidePrices ? null : (
            <strong>£{availability.price.toFixed(2)}</strong>
          )}
        </Text>
      </Flex>
    </Panel>
  );
};

type TyreStatTableProps = {
  tyre: TyreSearchPayload;
};

const SpecialtyStatTable = ({ tyre }: TyreStatTableProps) => {
  return (
    <StatTable>
      <tbody>
        <tr>
          <td>Construction</td>
          <td>{tyre.specialtyFitment?.construction}</td>
        </tr>
        {tyre.specialtyFitment?.tyreHeight && (
          <tr>
            <td>Height</td>
            <td>{tyre.specialtyFitment.tyreHeight}</td>
          </tr>
        )}
        {tyre.specialtyFitment?.section && (
          <tr>
            <td>Section</td>
            <td>{tyre.specialtyFitment.section}</td>
          </tr>
        )}
        {tyre.specialtyFitment?.profile && (
          <tr>
            <td>Profile</td>
            <td>{tyre.specialtyFitment.profile}</td>
          </tr>
        )}
        {tyre.specialtyFitment?.tyreWidth && (
          <tr>
            <td>Width</td>
            <td>{tyre.specialtyFitment.tyreWidth}</td>
          </tr>
        )}
        {tyre.specialtyFitment?.rimDiameter && (
          <tr>
            <td>Rim Diameter</td>
            <td>{tyre.specialtyFitment.rimDiameter}</td>
          </tr>
        )}
        {tyre.specialtyFitment?.rimWidth && (
          <tr>
            <td>Rim Width</td>
            <td>{tyre.specialtyFitment.rimWidth}</td>
          </tr>
        )}
        <tr>
          <td>LI/SR</td>
          <td>
            {tyre.load}
            {tyre.speed}
          </td>
        </tr>
        <tr>
          <td>TT/TL</td>
          <td>{tyre.tubeType}</td>
        </tr>
        <tr>
          <td>E Marked</td>
          <td>{tyre.eMark ? "Yes" : "No"}</td>
        </tr>
        <tr>
          <td>Ply Rating</td>
          <td>{tyre.ply} PR</td>
        </tr>
        <tr>
          <td>Tread Style</td>
          <td>
            {tyre.pattern.specialtyTreadStyle
              .map((x) => SpecialtyTreadStyles[x])
              .join(", ")}
          </td>
        </tr>
        {tyre.aramid && (
          <tr>
            <td>Aramid</td>
            <td>{tyre.aramid}</td>
          </tr>
        )}
      </tbody>
    </StatTable>
  );
};

const RegularStatTable = ({ tyre }: TyreStatTableProps) => {
  return (
    <StatTable>
      <tbody>
        <tr>
          <td>Season</td>
          <td>
            <TyreClassLabel
              text={seasons[tyre.season] ?? ""}
              tyreClass={seasonSrc[tyre.season] ?? "?"}
              color={
                seasonColors[tyre.season] != null
                  ? lighten(0.2, seasonColors[tyre.season])
                  : "grey3"
              }
            />
          </td>
        </tr>
        <tr>
          <td>Fuel Efficiency</td>
          <td>
            <TyreClassLabel
              text={tyre.vintage ? "Vintage" : tyre.por ? "POR" : ""}
              tyreClass={
                tyre.vintage || tyre.por ? "-" : tyre.fuelEfficiency || "?"
              }
              color={fuelEfficiencyColors[tyre.fuelEfficiency]}
            />
          </td>
        </tr>
        <tr>
          <td>Wet Grip</td>
          <td>
            <TyreClassLabel
              text={tyre.vintage ? "Vintage" : tyre.por ? "POR" : ""}
              tyreClass={tyre.vintage || tyre.por ? "-" : tyre.wetGrip || "?"}
              color={wetGripColors[tyre.wetGrip]}
            />
          </td>
        </tr>
        <tr>
          <td>Noise</td>
          <td>
            <TyreClassLabel
              text={
                tyre.vintage
                  ? "Vintage"
                  : tyre.por
                    ? "POR"
                    : tyre.tyreNoise === 0
                      ? ""
                      : `${tyre.tyreNoise}dB`
              }
              tyreClass={
                tyre.vintage || tyre.por
                  ? "-"
                  : tyre.tyreNoise === 0
                    ? "?"
                    : tyre.noiseClass.toString()
              }
              color={"grey3"}
            />
          </td>
        </tr>
        <tr>
          <td>Tyre Class</td>
          <td>
            <TyreClassLabel
              text={(tyre.class || "").replace("C", "Class ")}
              tyreClass={tyre.class ?? ""}
              color={"grey3"}
            />
          </td>
        </tr>
        <tr>
          <td>EAN</td>
          <td>
            <Text fontFamily={"mono"}>{tyre.ean}</Text>
          </td>
        </tr>
        <tr>
          <td>IPC</td>
          <td>
            <Text fontFamily={"mono"}>{tyre.ipc}</Text>
          </td>
        </tr>
      </tbody>
    </StatTable>
  );
};

export type AvailabilityMode = "delivery" | "collection";

export const TyreModal = ({
  stockCode,
  accountCode,
  onClose,
  showHistory = "no",
  basketItems,
  onAddToBasket,
  onStockCodeChange = () => {},
  hidePrices,
  onOrderHistorySelect,
  defaultSupply = "delivery",
}: TyreModalProps) => {
  const currentCode =
    typeof stockCode === "string"
      ? stockCode
      : stockCode.codes[stockCode.index];

  const tyre = useTyreByStockCode(currentCode, accountCode);
  const { user } = useAuth();
  const [showOrderHistory, setShowOrderHistory] = useState(false);
  const [count, setCount] = useState(1);
  const toasts = useToasts();
  const [supply, setSupply] = useState(defaultSupply);

  const inBasket = basketItems.find((x) => x.code === currentCode)?.qty ?? 0;

  useHotkeys("alt+c", () => {
    setShowOrderHistory((old) => !old);
  });

  const inc = () => setCount(Math.min(count + 1, qty));

  const dec = () => setCount(Math.max(count - 1, 0));

  const add = () => {
    if (accountCode != null) {
      if (onAddToBasket(count, supply)) {
        toasts.push({
          title: "Basket",
          content: "Item added to basket",
          icon: FaShoppingCart,
        });
      }
    }
  };

  const hasPattern = tyre.data?.pattern.id != null;

  const activeFlags =
    tyre.data == null
      ? []
      : flags
          .filter(
            (x) => (tyre.data as any)[x[1]] || (tyre.data.pattern as any)[x[1]],
          )
          .map((x) => x[0]);

  const hasRewards =
    tyre.data?.rewards != null &&
    Object.values(tyre.data.rewards).some((x) => x > 0);

  const showRewards = user?.type === "user" || user?.type === "staff";

  const canAdvance =
    typeof stockCode === "object" &&
    stockCode.index < stockCode.codes.length - 1;
  const canRetreat = typeof stockCode === "object" && stockCode.index > 0;

  const advance = () => {
    if (typeof stockCode === "string" || !canAdvance) {
      return;
    }

    onStockCodeChange({
      codes: stockCode.codes,
      index: stockCode.index + 1,
    });
  };

  const retreat = () => {
    if (typeof stockCode === "string" || !canRetreat) {
      return;
    }

    onStockCodeChange({
      codes: stockCode.codes,
      index: stockCode.index - 1,
    });
  };

  useHotkeys("left", () => retreat(), [retreat]);
  useHotkeys("right", () => advance(), [advance]);

  const supplyOptions = useSupplyOptions(accountCode ?? undefined);

  const groupedSupply =
    supply === "delivery"
      ? groupAvailabilities(tyre.data?.availability ?? [], inBasket + count)
      : groupCollections(
          (tyre.data?.collectionAvailability ?? []).filter(
            (x) => x.source.id === supply,
          ),
          inBasket + count,
        );

  const qty = Math.max(
    0,
    sum(groupedSupply.map((x) => x.qtyAvailable)) - inBasket,
  );

  useEffect(() => {
    setCount((old) => Math.min(old, Math.max(0, qty)));
  }, [qty]);

  return (
    <Modal
      shown={true}
      onClose={onClose}
      p={0}
      width={showOrderHistory ? 800 : undefined}
    >
      <PanelHeader justifyContent={["space-between"]}>
        <Text fontSize={[1, 3]} fontWeight={600}>
          <Text as="span" display={["none", "inline"]}>
            Stock Code:{" "}
          </Text>
          {currentCode}
        </Text>
        <Flex alignItems="center" ml={3}>
          {showHistory !== "no" && (
            <Button
              onClick={() => setShowOrderHistory(!showOrderHistory)}
              mr={2}
              small
              minWidth={100}
            >
              {showOrderHistory ? "Details" : "History"}
            </Button>
          )}
          <IconButton
            icon={FaChevronLeft}
            onClick={retreat}
            disabled={!canRetreat}
          />
          <IconButton
            icon={FaChevronRight}
            onClick={advance}
            disabled={!canAdvance}
            ml={2}
          />
          <IconButton icon={FaTimes} onClick={onClose} ml={2} />
        </Flex>
      </PanelHeader>
      {!tyre.isFetched ? (
        <Box p={3}>
          <Loader />
        </Box>
      ) : !tyre.isSuccess ? (
        <Text>Error</Text>
      ) : showOrderHistory ? (
        <OrderHistoryTable
          lockToAccount={showHistory === "locked"}
          onSelect={onOrderHistorySelect}
          showPrice={showHistory !== "locked"}
          stockCode={currentCode}
          accountCode={accountCode ?? undefined}
        />
      ) : (
        <Flex
          p={3}
          flexDirection={["column", "column", "row"]}
          style={{ gap: 12 }}
          alignItems={["flex-start"]}
          justifyContent="stretch"
        >
          <Flex
            flexDirection="column"
            width={["100%", "300px"]}
            flex="0 0 300px"
          >
            {tyre.data.pattern.logo ? (
              <PatternLogo
                src={process.env.REACT_APP_STATIC_ROOT + tyre.data.pattern.logo}
              />
            ) : (
              <Text fontWeight={600} fontSize={3} mb={0}>
                {tyre.data.pattern.name} {tyre.data.patternSuffix}
              </Text>
            )}
            {tyre.data.brand && (
              <div style={{ fontWeight: 600 }}>
                <ManufacturerLabel
                  mb={0}
                  code={tyre.data.brand.crossReference}
                  name={tyre.data.brand.name}
                  icon={tyre.data.brand.icon}
                  staticRoot={process.env.REACT_APP_STATIC_ROOT as string}
                />
              </div>
            )}
            <Text mb={2} fontSize={4} fontWeight={600}>
              {tyre.data.isSpecialty
                ? formatSpecialtyFitment(tyre.data.specialtyFitment!)
                : formatFullFitment(tyre.data)}
            </Text>

            {(tyre.data.pattern.images?.length > 0 ||
              tyre.data.eprelId !== 0) && (
              <Box mb={3} display={["block", "none"]}>
                <TyreImageWidget
                  images={tyre.data?.pattern.images.map(
                    (x) => process.env.REACT_APP_STATIC_ROOT + x,
                  )}
                  eprelId={tyre.data?.eprelId}
                />
              </Box>
            )}
            {tyre.data.isSpecialty ? (
              <SpecialtyStatTable tyre={tyre.data} />
            ) : (
              <RegularStatTable tyre={tyre.data} />
            )}
            {tyre.data.pattern.description && (
              <Text mb={2} fontSize={1}>
                {tyre.data.pattern.description}
              </Text>
            )}
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize={1}>
                Product Group: <strong>{tyre.data.productGroup}</strong>
              </Text>
              <Text fontSize={1}>
                Summary Code: <strong>{tyre.data.summaryCode}</strong>
              </Text>
            </Flex>
          </Flex>
          {(tyre.data.pattern.images?.length > 0 ||
            activeFlags.length > 0 ||
            tyre.data.threePeaks) && (
            <Flex
              flexDirection="column"
              justifyContent={["flex-start", "center"]}
              width={["100%", "300px"]}
            >
              {(tyre.data.pattern.images?.length > 0 ||
                tyre.data.eprelId !== 0) && (
                <Box mb={3} display={["none", "block"]}>
                  <TyreImageWidget
                    images={tyre.data?.pattern.images.map(
                      (x) => process.env.REACT_APP_STATIC_ROOT + x,
                    )}
                    eprelId={tyre.data?.eprelId}
                  />
                </Box>
              )}
              <Box pl={[0, hasPattern ? 5 : 0]} mt={!hasPattern ? 2 : 0}>
                {activeFlags.map((x) => (
                  <Flex alignItems="center" key={x}>
                    <FaCheck color="green" />
                    <Text ml={2} fontWeight={600}>
                      {x}
                    </Text>
                  </Flex>
                ))}
                {tyre.data.threePeaks && (
                  <Flex mt={2} alignItems="flex-end" ml={"-3px"}>
                    <ThreePeaksImage />
                    <Text ml={1} fontWeight={600}>
                      3PMSF
                    </Text>
                  </Flex>
                )}
              </Box>
            </Flex>
          )}
          {(tyre.data.availability.length > 0 ||
            tyre.data.collectionAvailability.length > 0 ||
            hasRewards) && (
            <Flex flexDirection="column" width={["100%", "300px"]}>
              {supplyOptions.options.length > 1 && (
                <Select
                  mb={1}
                  options={supplyOptions.options}
                  value={supply}
                  onChange={(ev) => setSupply(ev.target.value)}
                />
              )}
              {groupedSupply.length === 0 && <NoAvailabilities />}
              {groupedSupply.map((x, i) => (
                <AvailabilityDisplay
                  key={i}
                  taken={x.taken}
                  availability={x}
                  hidePrices={hidePrices}
                />
              ))}
              <Box mb={2}>
                <Flex
                  border={1}
                  borderRadius={6}
                  p={2}
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mb={1}
                >
                  <Flex alignItems="center" width={1}>
                    <Button
                      minWidth={0}
                      p={1}
                      mr={2}
                      width="36px"
                      onClick={dec}
                    >
                      -
                    </Button>
                    <Text
                      as="span"
                      fontSize={3}
                      lineHeight={1}
                      minWidth="72px"
                      textAlign={"center"}
                    >
                      {count} / {qty}
                    </Text>
                    <Button
                      minWidth={0}
                      p={1}
                      ml={2}
                      width="36px"
                      mr={2}
                      onClick={inc}
                    >
                      +
                    </Button>
                    <Button
                      p={1}
                      minWidth={0}
                      onClick={add}
                      disabled={count === 0}
                      flex={1}
                      icon={FaCartPlus}
                    >
                      Add
                    </Button>
                  </Flex>
                </Flex>
                {tyre.data.imprestQuantity > 0 && (
                  <Alert color="info" size="small">
                    <Text textAlign={"center"} width="100%" fontWeight={600}>
                      Imprest Qty: {tyre.data.imprestQuantity}
                    </Text>
                  </Alert>
                )}
                {inBasket > 0 && (
                  <Text color="positive" mt={1} textAlign="center">
                    {inBasket} in basket
                  </Text>
                )}
              </Box>
              {hasRewards && showRewards && (
                <Flex border={1} borderRadius={6} p={2} flexDirection="column">
                  <FormHeading mt={0}>Rewards Earned</FormHeading>
                  <MiniTable>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left" }}>Type</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Reward Points</td>
                        <td style={{ textAlign: "right" }}>
                          {tyre.data.rewards?.points === 0
                            ? "-"
                            : tyre.data.rewards?.points}
                        </td>
                      </tr>
                      <tr>
                        <td>Dealer Support Points</td>
                        <td style={{ textAlign: "right" }}>
                          {tyre.data.rewards?.dsp === 0
                            ? "-"
                            : tyre.data.rewards?.dsp}
                        </td>
                      </tr>
                      <tr>
                        <td>Business Development Fund</td>
                        <td style={{ textAlign: "right" }}>
                          {(tyre.data.rewards?.bdf ?? 0) === 0
                            ? "-"
                            : `£${((tyre.data.rewards?.bdf ?? 0) / 100).toFixed(
                                2,
                              )}`}
                        </td>
                      </tr>
                    </tbody>
                  </MiniTable>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
      )}
    </Modal>
  );
};

export * from "./TyreNoiseLabel";
