import { SpecialtyTyreDto, SpecialtyTyreSpec } from "@oaktyres/model";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getSpecialtyTyres = (query?: object) =>
  axios
    .get<SpecialtyTyreDto[]>("/api/v2/specialty-tyres", { params: query })
    .then(({ data }) => data);

const getSpecialtyTyreById = (id: string) =>
  axios
    .get<SpecialtyTyreDto>(`/api/v2/specialty-tyres/${id}`)
    .then(({ data }) => data);

const createSpecialtyTyre = (spec: SpecialtyTyreSpec) =>
  axios.post("/api/v2/specialty-tyres", spec);

const updateSpecialtyTyre = (spec: SpecialtyTyreSpec) =>
  axios.put(`/api/v2/specialty-tyres/${spec.id}`, spec);

type SpecialtyTyresQuery = {
  accountCode?: string;
  category?: string;
};

export const useSpecialtyTyres = (
  query?: SpecialtyTyresQuery,
  enabled: boolean = true,
) =>
  useQuery(["specialty-tyres", query], () => getSpecialtyTyres(query), {
    enabled: enabled,
  });

export const useSpecialtyTyreById = (id: string) =>
  useQuery(["specialty-tyres", id], () => getSpecialtyTyreById(id));

export const useUpsertSpecialtyTyre = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: SpecialtyTyreSpec) =>
      spec.id ? updateSpecialtyTyre(spec) : createSpecialtyTyre(spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["specialty-tyres"]);
      },
    },
  );

  return mutation;
};
