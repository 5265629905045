import {
  ExclusionPayload,
  ExclusionType,
  formatSpecialtyFitment,
  TyreSearchPayload,
} from "@oaktyres/model";
import {
  useExclusions,
  useFitments,
  useManufacturers,
  usePatterns,
  useSpecialtyFitments,
  useTyreOptions,
  useVehicleManufacturers,
} from "@oaktyres/queries";
import axios from "axios";
import { sortBy } from "lodash";
import { useCallback, useMemo } from "react";
import { formatFullFitment } from "../utils/formatFitment";

const speeds = [
  "B",
  "C",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "H",
  "V",
  "W",
  "Y",
  "Z",
];

export type SmartSearchCommon = {
  id: string;
  label: string;
};

export type SmartSearchSpecialtyFitment = SmartSearchCommon & {
  type: "specfitment";
};

export type SmartSearchFitment = SmartSearchCommon & {
  section: string;
  profile: string;
  rim: string;
  type: "fitment";
};

export type SmartSearchBrand = SmartSearchCommon & {
  crossReference: string;
  name: string;
  type: "brand";
};

export type SmartSearchIPC = SmartSearchCommon & {
  ipc: string;
  type: "ipc";
};

export type SmartSearchEAN = SmartSearchCommon & {
  ean: string;
  type: "ean";
};

export type SmartSearchStockCode = SmartSearchCommon & {
  stockCode: string;
  type: "stockcode";
};

export type SmartSearchSpeed = SmartSearchCommon & {
  speedRating: string;
  type: "speed";
};

export type SmartSearchLoad = SmartSearchCommon & {
  type: "load";
  load: string;
};

export type SmartSearchSeason = SmartSearchCommon & {
  type: "season";
  season: "W" | "A" | "S";
};

export type SmartSearchRunFlat = SmartSearchCommon & {
  type: "runflat";
  runflat: boolean;
};

export type SmartSearchCommercial = SmartSearchCommon & {
  type: "commercial";
  commercial: boolean;
};

export type SmartSearchBrandTier = SmartSearchCommon & {
  type: "brandtier";
  tier: number;
};

export type SmartSearchSetting = SmartSearchCommon & {
  type: "setting";
  setting: string;
};

export type SmartSearchPattern = SmartSearchCommon & {
  type: "pattern";
  crossReference: string;
};

export type SmartSearchVehicle = SmartSearchCommon & {
  type: "vehicle";
  vehicleId: number;
};

export type SmartSearchItem =
  | SmartSearchSpecialtyFitment
  | SmartSearchFitment
  | SmartSearchBrand
  | SmartSearchIPC
  | SmartSearchEAN
  | SmartSearchStockCode
  | SmartSearchSpeed
  | SmartSearchLoad
  | SmartSearchSeason
  | SmartSearchRunFlat
  | SmartSearchCommercial
  | SmartSearchBrandTier
  | SmartSearchSetting
  | SmartSearchPattern
  | SmartSearchVehicle;

const settingsOptions: SmartSearchSetting[] = [
  {
    id: "setting-nostock",
    label: "In Stock",
    type: "setting",
    setting: "instock",
  },
  {
    id: "setting-obsolete",
    label: "Include Obsolete",
    type: "setting",
    setting: "obsolete",
  },
];

export const getBrandOption = (crossReference: string, name: string) =>
  ({
    type: "brand",
    crossReference: crossReference,
    name: name,
    id: `brand:${crossReference}`,
    label: name,
  }) as const;

const runflatOption: SmartSearchRunFlat = {
  id: "runflat",
  label: "Runflat",
  type: "runflat",
  runflat: true,
} as const;

export const getRunflatOption = () => runflatOption;

const commercialOption: SmartSearchCommercial = {
  id: "commercial",
  label: "Commercial",
  type: "commercial",
  commercial: true,
} as const;

export const getCommercialOption = () => commercialOption;

const seasonOptions: SmartSearchSeason[] = [
  "Summer",
  "Winter",
  "All-Season",
].map(
  (x) =>
    ({
      id: `season-${x}`,
      label: x,
      type: "season",
      season: x.charAt(0) as any,
    }) as const,
);

const brandTierOptions: SmartSearchBrandTier[] = [
  "Budget",
  "Mid-Range",
  "Premium",
].map(
  (x, i) =>
    ({
      id: "brand-tier-" + x,
      label: x,
      type: "brandtier",
      tier: i,
    }) as const,
);

export const getBrandTierOption = (tier: 0 | 1 | 2) => brandTierOptions[tier];

export const getSeasonOption = (season: "A" | "S" | "W") =>
  seasonOptions.find((x) => x.season === season)!;

const getExclusionSet = (
  exclusions: ExclusionPayload[] | undefined,
  type: ExclusionType,
) =>
  new Set(
    (exclusions ?? [])
      .filter((x) => x.excludeFromSearch && x.type === type)
      .map((x) => x.key),
  );

export function useSmartSearch() {
  const { data: fitments } = useFitments();
  const { data: specFitmentsData } = useSpecialtyFitments();
  const { data: brands } = useManufacturers();
  const { data: vehicles } = useVehicleManufacturers();
  const { data: exclusions } = useExclusions();
  const { data: patterns } = usePatterns();
  const { data: options } = useTyreOptions();

  const specFitments = useMemo(
    () =>
      (specFitmentsData ?? [])
        .filter((x) => x.stockCodes.length > 0)
        .map((x) => ({
          id: x.id,
          label: formatSpecialtyFitment(x),
        })),
    [specFitmentsData],
  );

  const loads = useMemo(
    () => options?.loadOptions?.map((x) => x.key) ?? [],
    [options],
  );

  const excludedBrands = useMemo(
    () => getExclusionSet(exclusions, ExclusionType.Brand),
    [exclusions],
  );
  const excludedSpeeds = useMemo(
    () => getExclusionSet(exclusions, ExclusionType.Speed),
    [exclusions],
  );
  const excludedLoads = useMemo(
    () => getExclusionSet(exclusions, ExclusionType.Load),
    [exclusions],
  );
  const excludedSections = useMemo(
    () => getExclusionSet(exclusions, ExclusionType.Section),
    [exclusions],
  );
  const excludedProfiles = useMemo(
    () => getExclusionSet(exclusions, ExclusionType.Profile),
    [exclusions],
  );
  const excludedRims = useMemo(
    () => getExclusionSet(exclusions, ExclusionType.Rim),
    [exclusions],
  );

  const search = useCallback(
    async (input: string): Promise<SmartSearchItem[]> => {
      const isReady = [
        loads,
        patterns,
        vehicles,
        exclusions,
        fitments,
        brands,
      ].every((x) => x != null);
      if (input === "" || !isReady) {
        return [];
      }

      if (input.length > 9) {
        try {
          const { data: rData } = await axios.get<TyreSearchPayload[]>(
            `/api/v2/tyres/${input}`,
          );
          if (rData.length > 0) {
            const data = rData[0];
            if (data.ean === input) {
              return [
                {
                  type: "ean",
                  id: data.stockCode,
                  label: `${data.brand.name} - ${formatFullFitment(data)}`,
                  ean: data.ean,
                },
              ];
            } else if (data.ipc === input) {
              return [
                {
                  type: "ipc",
                  id: data.stockCode,
                  label: `${data.brand.name} - ${formatFullFitment(data)}`,
                  ipc: data.ipc,
                },
              ];
            }
            return [
              {
                type: "stockcode",
                id: data.stockCode,
                label: `${data.brand.name} - ${formatFullFitment(data)}`,
                stockCode: data.stockCode,
              },
            ];
          }
        } catch {}
      }

      const rf = "runflat".includes(input.replace(/\W/g, "").toLowerCase())
        ? [runflatOption]
        : [];

      const settings = settingsOptions.filter((x) =>
        x.label
          .toLowerCase()
          .replace(/\W/g, "")
          .includes(input.toLowerCase().replace(/\W/g, "")),
      );

      const commercial = ["van", "commercial"].some((x) =>
        x.includes(input.toLowerCase()),
      )
        ? [commercialOption]
        : [];

      const seasons = seasonOptions.filter((x) =>
        x.label
          .toLowerCase()
          .replace(/\W/g, "")
          .includes(input.replace(/\W/g, "").toLowerCase()),
      );

      const tiers = brandTierOptions.filter((x) =>
        x.label
          .toLowerCase()
          .replace(/\W/g, "")
          .includes(input.replace(/\W/g, "").toLowerCase()),
      );

      const l = loads!
        .filter((x) => !excludedLoads.has(x))
        .flatMap((x) => [x, ">" + x])
        .filter((x) => x.startsWith(input))
        .map<SmartSearchLoad>((x) => ({
          type: "load",
          id: "load:" + x,
          label: x,
          load: x,
        }));

      const p: SmartSearchPattern[] = patterns!
        .filter((x) => x.name.toLowerCase().includes(input.toLowerCase()))
        .map((x) => ({
          type: "pattern",
          id: `pattern-${x.id}`,
          label: x.name,
          crossReference: x.crossReference,
        }));

      const allowedSpeed = speeds.filter((x) => !excludedSpeeds.has(x));

      const speed = [...allowedSpeed, ...allowedSpeed.map((x) => ">" + x)].find(
        (x) => x === input.toUpperCase(),
      );

      const s: SmartSearchSpeed[] =
        speed == null
          ? []
          : [
              {
                type: "speed",
                speedRating: speed,
                label: speed,
                id: "speed:" + speed,
              },
            ];

      const numbersOnly = input.replace(/[^0-9.]*/g, "");

      const spec: SmartSearchSpecialtyFitment[] =
        numbersOnly.length < 3
          ? []
          : specFitments
              .filter((x) => x.label.replace(/[^0-9.]*/g, "").startsWith(input))
              .map((x) => ({
                id: x.id,
                label: x.label,
                type: "specfitment",
              }));

      const f: SmartSearchFitment[] =
        numbersOnly.length < 3
          ? []
          : sortBy(
              fitments!
                .filter((x) => {
                  return (
                    !excludedSections.has(x.section) &&
                    !excludedProfiles.has(x.profile) &&
                    !excludedRims.has(x.rim)
                  );
                })
                .filter(
                  (x) =>
                    !x.isInvalid &&
                    [...x.aliases, `${x.section}${x.profile}${x.rim}`].some(
                      (x) => x.includes(numbersOnly),
                    ),
                ),
              "popularity",
            )
              .reverse()
              .map((x) => ({
                type: "fitment",
                section: x.section,
                profile: x.profile,
                rim: x.rim,
                label: `${x.section}/${x.profile} R${x.rim}`,
                id: `fitment:${x.section}/${x.profile}/${x.rim}`,
              }));

      const b = sortBy(
        brands!
          .filter((x) => !excludedBrands.has(x.crossReference))
          .filter((x) => x.name.toLowerCase().includes(input.toLowerCase())),
        "popularity",
      )
        .reverse()
        .map<SmartSearchBrand>((x) => getBrandOption(x.crossReference, x.name));

      const v = vehicles!
        .filter((x) => x.name.toLowerCase().includes(input.toLowerCase()))
        .map<SmartSearchVehicle>((x) => ({
          type: "vehicle",
          id: `vehicle:${x.id}`,
          label: x.name,
          vehicleId: x.id!,
        }));

      return [
        s,
        rf,
        seasons,
        commercial,
        tiers,
        settings,
        l,
        f,
        spec,
        b,
        v,
        p,
      ].flatMap<SmartSearchItem>((x) => x.slice(0, 10));
    },
    [
      exclusions,
      brands,
      fitments,
      loads,
      patterns,
      vehicles,
      excludedBrands,
      excludedLoads,
      excludedProfiles,
      excludedRims,
      excludedSections,
      excludedSpeeds,
    ],
  );

  return { search };
}
