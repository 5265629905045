import { TyreQueryDto } from "@oaktyres/model";
import { sortBy, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import {
  FaBarcode,
  FaCar,
  FaCircle,
  FaHammer,
  FaPoundSign,
  FaRing,
  FaSnowflake,
  FaTachometerAlt,
  FaTag,
  FaTruck,
  FaWeightHanging,
  FaWrench,
} from "react-icons/fa";
import styled from "styled-components";
import { Flex, Label, LabelInput, Panel, Text } from "../..";
import {
  SmartSearchBrand,
  SmartSearchBrandTier,
  SmartSearchCommercial,
  SmartSearchEAN,
  SmartSearchFitment,
  SmartSearchItem,
  SmartSearchLoad,
  SmartSearchPattern,
  SmartSearchRunFlat,
  SmartSearchSeason,
  SmartSearchSpecialtyFitment,
  SmartSearchSpeed,
  SmartSearchStockCode,
  SmartSearchVehicle,
  useSmartSearch,
} from "../../hooks/useSmartSearch";
import { useDebounce } from "../../utils/useDebounce";

const ListPanel = styled(Panel)`
  position: absolute;
  width: 100%;
  left: 0;
  top: 90px;
  z-index: 2000;
`;

const types = {
  specfitment: "Specialty Fitment",
  fitment: "Fitment",
  brand: "Brand",
  ipc: "IPC",
  ean: "EAN",
  stockcode: "Stock Code",
  speed: "Speed",
  load: "Load Index",
  season: "Season",
  brandtier: "Brand Tier",
  runflat: "Feature",
  commercial: "Feature",
  setting: "Setting",
  pattern: "Pattern",
  vehicle: "Vehicle",
};

const typeIcons = {
  specfitment: FaRing,
  fitment: FaRing,
  brand: FaHammer,
  speed: FaTachometerAlt,
  stockcode: FaBarcode,
  load: FaWeightHanging,
  ipc: FaBarcode,
  ean: FaBarcode,
  runflat: FaCircle,
  brandtier: FaPoundSign,
  season: FaSnowflake,
  commercial: FaTruck,
  setting: FaWrench,
  pattern: FaTag,
  vehicle: FaCar,
};

const typeColors = {
  specfitment: "primary",
  fitment: "primary",
  brand: "purple",
  speed: "positive",
  load: "grey2",
  stockcode: "accent",
  ipc: "danger",
  ean: "danger",
  runflat: "positive",
  brandtier: "accent",
  season: "cyan",
  commercial: "grey2",
  setting: "grey2",
  pattern: "danger",
  vehicle: "warning",
};

export const ListItem = ({
  item,
  active,
  ...rest
}: {
  item: SmartSearchItem;
  active: boolean;
} & React.ComponentPropsWithoutRef<"div">) => {
  return (
    <Flex
      p={1}
      style={{ backgroundColor: active ? "#ddd" : "white", cursor: "pointer" }}
      alignItems="center"
      {...rest}
    >
      <Label mr={2} color={typeColors[item.type]} withIcon>
        {React.createElement(typeIcons[item.type], {
          style: { marginRight: 6 },
        })}
        {types[item.type]}
      </Label>
      <Text mb={0}>{item.label}</Text>
    </Flex>
  );
};

type SmartSearchProps = {
  value: SmartSearchItem[];
  onChange: (newValue: SmartSearchItem[]) => void;
};

export const paramsFromSmartSearch = (
  items: SmartSearchItem[],
): TyreQueryDto[] | null => {
  const stockcode = items.find(
    (x) => x.type === "stockcode",
  ) as SmartSearchStockCode;

  if (stockcode != null) {
    return [
      {
        stockCode: stockcode.stockCode,
        showObsolete: false,
        hideNoStock: false,
      },
    ];
  }

  const ean = items.find((x) => x.type === "ean") as SmartSearchEAN;

  if (ean != null) {
    return [
      {
        ean: ean.ean,
        showObsolete: false,
        hideNoStock: false,
      },
    ];
  }

  const fitments: SmartSearchFitment[] | null = items.filter(
    (x) => x.type === "fitment",
  ) as SmartSearchFitment[];

  if (fitments.length === 0) {
    return null;
  }

  const sorted = sortBy(fitments, "section");
  const front = sorted[0];
  const rear = sorted[1];

  const vehicle = items.find((x) => x.type === "vehicle") as SmartSearchVehicle;
  const brand = items.find((x) => x.type === "brand") as SmartSearchBrand;
  const speed = items.find((x) => x.type === "speed") as SmartSearchSpeed;
  const load = items.find((x) => x.type === "load") as SmartSearchLoad;
  const runflat = items.find((x) => x.type === "runflat") as SmartSearchRunFlat;
  const commercial = items.find(
    (x) => x.type === "commercial",
  ) as SmartSearchCommercial;
  const tier = items.find(
    (x) => x.type === "brandtier",
  ) as SmartSearchBrandTier;
  const season = items.find((x) => x.type === "season") as SmartSearchSeason;
  const pattern = items.find((x) => x.type === "pattern") as SmartSearchPattern;
  const hideNoStock = items.some(
    (x) => x.type === "setting" && x.setting === "instock",
  );
  const showObsolete = items.some(
    (x) => x.type === "setting" && x.setting === "obsolete",
  );

  const removeNull = (p: TyreQueryDto): TyreQueryDto =>
    Object.fromEntries(
      Object.entries(p).filter((x) => x[1] != null),
    ) as TyreQueryDto;

  const frontParams = {
    section: front.section,
    profile: front.profile,
    rim: front.rim,
    pattern: pattern?.crossReference,
    brand: brand?.crossReference ?? "",
    speed: speed?.speedRating ?? "",
    load: load?.load ?? "",
    hideNoStock,
    showObsolete,
    season: season?.season,
    runflat: runflat?.runflat,
    commercial: commercial?.commercial,
    brandTier: tier?.tier,
    vehicle: vehicle?.vehicleId,
  };

  if (rear == null) {
    return [frontParams].map(removeNull);
  }

  const rearParams = {
    ...frontParams,
    section: rear.section,
    profile: rear.profile,
    rim: rear.rim,
  };

  return [frontParams, rearParams].map(removeNull);
};

export function SmartSearch({ value, onChange }: SmartSearchProps) {
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [available, setAvailable] = useState<SmartSearchItem[]>([]);

  const { search } = useSmartSearch();

  const debouncedInput = useDebounce(input, 100);

  useEffect(() => {
    if (debouncedInput === "") {
      setAvailable([]);
    } else {
      search(debouncedInput).then((res) => {
        setActiveIndex(0);
        setAvailable(res);
      });
    }
  }, [debouncedInput, search]);

  const select = (i: number) => {
    const item = available[i];
    const old = value;
    let sel: SmartSearchItem[] = [];
    if (["stockcode", "ipc", "ean"].includes(item.type)) {
      sel = [item];
    } else {
      [...old, item].forEach((x) => {
        if (
          [
            "load",
            "speed",
            "runflat",
            "season",
            "commercial",
            "vehicle",
          ].includes(x.type)
        ) {
          sel = [...sel.filter((s) => s.type !== x.type), x];
        } else if (["brand", "brandtier", "pattern"].includes(x.type)) {
          sel = [
            ...sel.filter(
              (s) => !["brand", "brandtier", "pattern"].includes(s.type),
            ),
            x,
          ];
        } else if (x.type === "fitment") {
          const f = uniqBy(
            [...sel.filter((x) => x.type === "fitment"), x],
            "id",
          )
            .reverse()
            .slice(0, 2)
            .reverse();
          sel = [...sel, x].filter(
            (x) => x.type !== "fitment" || f.includes(x),
          );
        } else if (x.type === "setting") {
          sel.push(x);
        } else if (x.type === "specfitment") {
          sel = [...sel.filter((x) => x.type !== "fitment"), x];
        }
      });
    }
    const newValue = uniqBy(sel, "id");
    setInput("");
    setAvailable([]);
    onChange(newValue);
  };

  const deselect = (id: string) => {
    onChange(value.filter((x) => x.id !== id));
  };

  const selectedItems = value.map((x) => ({
    id: x.id,
    color: typeColors[x.type],
    icon: typeIcons[x.type],
    label: x.label,
  }));

  const shake = () => {
    window.alert("Invalid Search Term");
  };

  const onKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === "Enter") {
      if (available[activeIndex] != null) {
        select(activeIndex);
      } else {
        shake();
      }
    } else if ((!ev.shiftKey && ev.key === "Tab") || ev.key === "ArrowDown") {
      setActiveIndex((old) => (old + 1) % available.length);
      ev.preventDefault();
    } else if ((ev.shiftKey && ev.key === "Tab") || ev.key === "ArrowUp") {
      setActiveIndex((old) => (old === 0 ? available.length - 1 : old - 1));
      ev.preventDefault();
    } else if (
      ev.key === "Backspace" &&
      input.length === 0 &&
      value.length > 0
    ) {
      onChange(value.slice(0, -1));
    }
  };

  return (
    <div style={{ position: "relative", flex: 1 }}>
      <LabelInput
        onChange={(ev) => setInput(ev.target.value)}
        items={selectedItems}
        value={input}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onItemRemove={deselect}
        onKeyDown={onKeyDown}
      />
      {hasFocus && available.length > 0 && (
        <ListPanel>
          {available.map((x, i) => (
            <ListItem
              key={x.id}
              item={x}
              active={activeIndex === i}
              onMouseEnter={() => setActiveIndex(i)}
              onMouseDown={(ev) => ev.preventDefault()}
              onClick={() => select(i)}
            />
          ))}
        </ListPanel>
      )}
    </div>
  );
}

export * from "./common";
