import { TyreQueryDto, TyreSearchPayload } from "@oaktyres/model";
import {
  useAccount,
  useAccountNew,
  useDeliveryInfo,
  useSellOutLists,
  useSupplyOptions,
  useTyres,
} from "@oaktyres/queries";
import {
  Box,
  buildFilterSetFromData,
  Flex,
  groupResults,
  IconButton,
  Label,
  LinkButton,
  Loader,
  MinimumQtyFilter,
  MiniToggle,
  PairStockStatus,
  Panel,
  PanelHeader,
  paramsFromSmartSearch,
  Select,
  SmartSearch,
  Text,
  TyreFilters,
  TyreModal,
  FilterSidebar,
  StockCodeSet,
  Circle,
  Button,
} from "@oaktyres/ui";
import React, { useCallback, useMemo, useState } from "react";
import { FaArrowCircleUp, FaCog, FaMinusCircle, FaStar } from "react-icons/fa";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { getDefaultFeatures, TyreTable } from "./TyreTable";
import smartSearchLogoSrc from "../../img/smartsearchhoriz.png";
import styled from "styled-components";
import { SmartSearchItem } from "@oaktyres/ui/dist/hooks/useSmartSearch";
import { useEffect } from "react";
import { SuggestionBlockBar } from "./SuggestionBlock";
import { useScopedBasket } from "../../basket";
import { isEqual } from "lodash";
import { useRetailView } from "../../components/RetailViewProvider";
import { useTyreShopSettings, VatModal } from "./VatModal";
import { useWindowSize } from "react-use";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const SmartSearchLogo = styled.img.attrs({ src: smartSearchLogoSrc })`
  max-width: 150px;
  margin-right: 12px;
`;

type SpecialtyFitmentMessageProps = {
  id: string;
};

const SpecialtyFitmentMessage = ({ id }: SpecialtyFitmentMessageProps) => {
  return (
    <Flex
      flexDirection="column"
      p={5}
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Circle icon={FaStar} color="primary" size="xxlarge" mb={3} />
      <Text
        fontSize={3}
        fontWeight={600}
        color="grey3"
        mt={1}
        mb={3}
        textAlign="center"
      >
        This search contains a{" "}
        <Text as="strong" color="primary">
          Specialty Fitment.
        </Text>
        <br />
        These can be found in our Specialty Fitment Store.
      </Text>
      <Button to="/store/specialty" icon={FaStar}>
        Go to Specialty Fitment Store
      </Button>
    </Flex>
  );
};

const NoQuery = () => (
  <Flex
    flexDirection="column"
    p={5}
    justifyContent="center"
    alignItems="center"
    width="100%"
  >
    <FaArrowCircleUp color="#ccc" size="5em" />
    <Text fontSize={5} fontWeight={600} color="grey3" mt={1} mb={2}>
      Start Typing to Search
    </Text>
    <LinkButton
      onClick={() => alert("Maybe an explainer video here?")}
      style={{ display: "none" }}
    >
      How does this work?
    </LinkButton>
  </Flex>
);

const NoResults = () => (
  <Flex
    flexDirection="column"
    p={5}
    justifyContent="center"
    alignItems="center"
    flex={1}
  >
    <FaMinusCircle color="#ccc" size="5em" />
    <Text fontSize={5} fontWeight={600} color="grey3" mt={1} mb={2}>
      No Results
    </Text>
  </Flex>
);

const mobFeatures = getDefaultFeatures().filter(
  (x) => !["fuelEfficiency", "wetGrip", "tyreNoise"].includes(x),
);

export const TyreStorePage = () => {
  const [smart, setSmart] = useState<SmartSearchItem[]>([]);
  const [accountCode] = useScopedAccount();
  const account = useAccountNew(accountCode);
  const selloutLists = useSellOutLists(accountCode);
  const [selectedSellOutList, setSelectedSellOutList] = useState<string>("");
  const [retailView, setRetailView] = useRetailView();
  const { items, addToBasket } = useScopedBasket();
  const [settings, setSettings] = useTyreShopSettings();
  const [showSettings, setShowSettings] = useState(false);

  const [supply, setSupply] = useState(
    settings.defaultSupply[accountCode] ?? "delivery",
  );
  const supplyOptions = useSupplyOptions(accountCode);
  const ws = useWindowSize();
  const history = useHistory();

  const collection = supply !== "delivery";

  const setCollection = (val: boolean) => {
    setSupply(
      val
        ? supplyOptions.options.find((x) => x.value !== "delivery")!.value
        : "delivery",
    );
  };

  const [lastSmart, setLastSmart] = useState(() => {
    return JSON.parse(window.localStorage.getItem("op_smart") ?? "null") as
      | SmartSearchItem[]
      | null;
  });

  const sellOutOptions =
    selloutLists.data?.map((x) => ({
      value: x.id,
      label: `Sell Out: ${x.name}`,
    })) ?? null;

  const activeSellOutList =
    selloutLists.data?.find((x) => x.id === selectedSellOutList) ??
    selloutLists.data?.[0];

  useEffect(() => {
    if (!supplyOptions.loading) {
      const def = settings.defaultSupply[accountCode];

      if (supplyOptions.options.some((x) => x.value === def)) {
        setSupply(def);
      } else {
        setSupply(supplyOptions.options[0]?.value ?? "delivery");
      }
    }
  }, [supplyOptions, accountCode]);

  useEffect(() => {
    const lastList = window.localStorage.getItem(`selloutlist-${accountCode}`);
    setSelectedSellOutList(lastList ?? "");
  }, [accountCode]);

  useEffect(() => {
    window.localStorage.setItem(
      `selloutlist-${accountCode}`,
      selectedSellOutList,
    );
  }, [selectedSellOutList]);

  const query = useMemo(
    () => paramsFromSmartSearch(smart) ?? [null, null],
    [smart],
  );

  const getQuery = (q: TyreQueryDto | null) =>
    q == null ? null : { ...q, accountCode };

  const front = useTyres(getQuery(query[0]));
  const rear = useTyres(getQuery(query[1]));
  const [minQty, setMinQty] = useState<number>(1);
  const [shownCode, setShownCode] = useState<StockCodeSet | null>(null);

  const filters = useMemo(
    () =>
      buildFilterSetFromData(
        (front.data ?? []).concat(rear.data ?? []),
        collection ? "collection" : "delivery",
      ),
    [front.data, rear.data, collection],
  );
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const onTyreClick = useCallback(
    (_: string, rowIndex: number, sortedData: TyreSearchPayload[]) => {
      setShownCode({
        codes: sortedData.map((x) => x.stockCode),
        index: rowIndex,
      });
    },
    [],
  );

  const tableFeatures = useMemo(
    () =>
      getDefaultFeatures()
        .filter((x) => !retailView || x !== "price")
        .filter((x) => ws.width > 768 || mobFeatures.includes(x)),
    [retailView, ws.width],
  );

  useEffect(() => {
    setActiveFilters((old) =>
      old.filter(
        (x) =>
          x === "Availability.Next Van" || x === "Availability.Collect Now",
      ),
    );
  }, [query]);

  let filteredData = useMemo(() => {
    const filterFunctions = activeFilters.map(
      (x) => filters[x.split(".")[0] || ""][x.split(".")[1]],
    );

    return (
      front.data
        ?.concat(rear.data ?? [])
        .map((x) => ({
          ...x,
          totalStock:
            supply === "delivery"
              ? x.totalStock
              : (x.collectionAvailability.find((x) => x.source.id === supply)
                  ?.qtyAvailable ?? 0),
        }))
        .filter((x) => filterFunctions.every((fn) => fn(x, minQty)))
        .filter((x) => x.totalStock >= minQty)
        .filter((x) => x.price?.value != null) ?? []
    );
  }, [
    front.data,
    rear.data,
    activeFilters,
    filters,
    minQty,
    collection,
    supply,
  ]);

  const isLoading = front.isLoading || rear.isLoading;
  const emptyQuery = query[0] == null;
  const frontOnly = query[0] != null && query[1] == null;
  const noResults = !isLoading && !emptyQuery && filteredData.length === 0;
  const canRestoreSearch =
    lastSmart != null && lastSmart.length > 0 && !isEqual(lastSmart, smart);

  const restoreSearch = () => {
    if (lastSmart != null) {
      setSmart(lastSmart);
      setLastSmart(null);
    }
  };

  const groupedResults = useMemo(() => {
    if (query[0] == null || query[1] == null) {
      return [];
    }

    return groupResults(filteredData, query[0]!, query[1]!);
  }, [filteredData, query]);

  const onSmartSearchChange = (newItems: SmartSearchItem[]) => {
    if (newItems.length > 0) {
      window.localStorage.setItem("op_smart", JSON.stringify(newItems));
    } else {
      window.localStorage.removeItem("op_smart");
    }
    setSmart(newItems);
  };

  const deliverySwitch =
    supplyOptions.options.length === 2 &&
    supplyOptions.options.some((x) => x.value === "delivery");

  const multipleSupply = supplyOptions.options.length > 1;

  const specialtyFitmentId = smart.find((x) => x.type === "specfitment")?.id;

  return (
    <Box width={"100%"}>
      {shownCode && (
        <TyreModal
          stockCode={shownCode}
          onClose={() => setShownCode(null)}
          defaultSupply={supply}
          accountCode={accountCode}
          basketItems={items}
          showHistory="locked"
          onStockCodeChange={(code) => setShownCode(code)}
          onOrderHistorySelect={(item) =>
            history.push(`/orders?search=${item.invoice}`)
          }
          onAddToBasket={(count, supply) =>
            addToBasket(shownCode.codes[shownCode.index], count, supply)
          }
          hidePrices={retailView}
        />
      )}
      {showSettings && (
        <VatModal
          value={settings}
          accountCode={accountCode}
          onChange={setSettings}
          onClose={() => setShowSettings(false)}
        />
      )}
      <Panel mb={2} p={3}>
        <Flex
          height={["auto", 37]}
          alignItems={["stretch", "flex-end"]}
          mb={2}
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Flex justifyContent={"space-between"} alignItems="center">
            <SmartSearchLogo />
            <IconButton
              icon={FaCog}
              onClick={() => setShowSettings(true)}
              color="body"
              display={["block", "none"]}
            />
          </Flex>
          <Flex
            alignItems={["stretch", "stretch"]}
            mt={[2, 0]}
            flexDirection={["column", "row"]}
          >
            {deliverySwitch ? (
              <MiniToggle
                value={collection}
                label="Delivery"
                rightLabel="Collection"
                onChange={setCollection}
                mb={[2, 0]}
                mr={[0, 2]}
              />
            ) : multipleSupply ? (
              <Select
                options={supplyOptions.options}
                onChange={(ev) => setSupply(ev.target.value)}
                value={supply}
                minWidth={0}
                width={["100%", "220px"]}
                mb={[2, 0]}
                mr={[0, 2]}
              />
            ) : null}
            <MiniToggle
              value={retailView}
              label="Retail View"
              onChange={setRetailView}
              mb={[2, 0]}
            />
            {sellOutOptions != null && sellOutOptions.length > 0 && (
              <Flex width={["100%", 200]} ml={[0, 2]}>
                <Select
                  options={sellOutOptions}
                  value={selectedSellOutList}
                  onChange={(ev) => setSelectedSellOutList(ev.target.value)}
                />
              </Flex>
            )}
            <IconButton
              icon={FaCog}
              onClick={() => setShowSettings(true)}
              color="body"
              ml={2}
              display={["none", "block"]}
            />
          </Flex>
        </Flex>
        <SmartSearch value={smart} onChange={onSmartSearchChange} />
        <Flex justifyContent={"space-between"}>
          {canRestoreSearch ? (
            <LinkButton onClick={restoreSearch} fontSize={1} mt="3px">
              Restore last search
            </LinkButton>
          ) : (
            <div />
          )}
          <Text fontSize={"10px"} mt="6px" textAlign="right">
            A stock code, ean or at least one fitment is required to search
          </Text>
        </Flex>
      </Panel>
      {specialtyFitmentId ? (
        <SpecialtyFitmentMessage id={specialtyFitmentId} />
      ) : emptyQuery ? (
        <NoQuery />
      ) : (
        <>
          <SuggestionBlockBar
            items={filteredData}
            account={account.data}
            currentParams={smart}
            onNewParams={setSmart}
            selloutListId={activeSellOutList?.id}
          />
          <Flex style={{ gap: 12 }} alignItems="flex-start">
            {isLoading && <Loader overlay />}
            <FilterSidebar>
              <Flex flexDirection={"column"} p={3}>
                <MinimumQtyFilter value={minQty} onChange={setMinQty} />
                <TyreFilters
                  filters={filters}
                  minQty={minQty}
                  data={filteredData}
                  value={activeFilters}
                  onChange={setActiveFilters}
                />
              </Flex>
            </FilterSidebar>
            {noResults ? (
              <NoResults />
            ) : frontOnly ? (
              <Panel flex={1} minWidth={0}>
                <Box style={{ overflowX: "auto", minWidth: 0 }}>
                  <TyreTable
                    data={filteredData}
                    onStockCodeClick={onTyreClick}
                    sellOutId={activeSellOutList?.id}
                    sellOutIncVat={activeSellOutList?.applyVat}
                    features={tableFeatures}
                    defaultDesc={
                      settings.defaultSort === "price" ? false : undefined
                    }
                    defaultSort={
                      settings.defaultSort === "default"
                        ? undefined
                        : tableFeatures.includes("price")
                          ? tableFeatures.indexOf("price")
                          : tableFeatures.includes("sellOutPrice")
                            ? tableFeatures.indexOf("sellOutPrice")
                            : undefined
                    }
                  />
                </Box>
              </Panel>
            ) : (
              <Flex flexDirection={"column"} style={{ gap: 12 }} flex="1">
                {groupedResults?.map((x, i) => (
                  <Panel flex="1" key={x.heading ?? i} minWidth={0}>
                    {x.heading && (
                      <PanelHeader justifyContent="space-between">
                        <Text fontSize={2} fontWeight={600} mr={1}>
                          {x.heading}
                        </Text>
                        {x.stockStatus ===
                        PairStockStatus.Ignore ? null : x.stockStatus ===
                          PairStockStatus.Both ? (
                          <Label color="positive" small>
                            Both in stock
                          </Label>
                        ) : x.stockStatus === PairStockStatus.FrontOnly ? (
                          <Label color="warning" small>
                            Front in stock
                          </Label>
                        ) : x.stockStatus === PairStockStatus.RearOnly ? (
                          <Label color="warning" small>
                            Rear in stock
                          </Label>
                        ) : (
                          <Label color="danger" small>
                            Neither in stock
                          </Label>
                        )}
                      </PanelHeader>
                    )}
                    <TyreTable
                      data={x.items}
                      onStockCodeClick={onTyreClick}
                      sellOutId={activeSellOutList?.id}
                      sellOutIncVat={activeSellOutList?.applyVat}
                      features={tableFeatures}
                    />
                  </Panel>
                ))}
              </Flex>
            )}
          </Flex>
        </>
      )}
    </Box>
  );
};
