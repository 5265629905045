import { formatSpecialtyFitment, SpecialtyFitmentDto } from "@oaktyres/model";
import { useProductCategories, useSpecialtyFitments } from "@oaktyres/queries";
import { Grid, Panel, Select } from "@oaktyres/ui";
import { groupBy, sortBy, uniq } from "lodash";
import React from "react";
import { useMemo } from "react";

export type SpecialtyFitmentQuery = {
  diameter: string;
  size: string;
  category: string;
};

export type SpecialtyFitmentQueryPanelProps = {
  value: SpecialtyFitmentQuery;
  onChange: (value: SpecialtyFitmentQuery) => void;
};

export const SpecialtyFitmentQueryPanel = ({
  value,
  onChange,
}: SpecialtyFitmentQueryPanelProps) => {
  const fitments = useSpecialtyFitments();
  const categories = useProductCategories();

  const cats = (categories.data ?? []).filter((x) => x.store === "specialty");

  const allowedFitments = useMemo(
    () => (fitments.data ?? []).filter((x) => x.stockCodes.length > 0),
    [fitments.data],
  );

  const diameterOptions = useMemo(
    () => [
      { value: "", label: "Rim Diameter" },
      ...uniq(allowedFitments.map((x) => x.rimDiameter))
        .sort((a, b) => a - b)
        .map((x) => ({
          value: x.toString(),
          label: x.toString() + '"',
        })),
    ],
    [allowedFitments],
  );

  const sizeOptions = useMemo(
    () => [
      { value: "", label: "Tyre Size" },
      ...Object.entries(
        groupBy(
          allowedFitments.filter(
            (x) =>
              value.diameter === "" ||
              value.diameter === x.rimDiameter.toString(),
          ),
          (x) => x.rimDiameter,
        ),
      ).flatMap(([dia, fits]) => {
        return [
          { value: dia.toString(), label: `──── ${dia}" ────`, disabled: true },
          ...fits
            .sort((a, b) => {
              const af = formatSpecialtyFitment(a);
              const bf = formatSpecialtyFitment(b);
              if (af < bf) {
                return -1;
              }

              if (af > bf) {
                return 1;
              }

              return 0;
            })
            .map((x) => ({
              value: x.id,
              label: formatSpecialtyFitment(x),
            })),
        ];
      }),
    ],
    [allowedFitments, diameterOptions, value.diameter],
  );

  const appOptions = [
    { value: "", label: "Application" },
    ...sortBy(cats, (x) => x.name).map((x) => ({
      value: x.id,
      label: x.name,
    })),
  ];

  const { diameter, size, category } = value;

  const setSize = (val: string) => {
    if (val === "") {
      onChange({
        ...value,
        size: val,
      });
    }

    const fitment = allowedFitments.find((x) => x.id === val);

    onChange({
      ...value,
      size: val,
      diameter: fitment!.rimDiameter.toString(),
    });
  };

  const setDiameter = (val: string) => {
    onChange({
      ...value,
      diameter: val,
      size: "",
    });
  };

  const change =
    (field: keyof SpecialtyFitmentQuery) =>
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      onChange({
        ...value,
        [field]: ev.target.value,
      });
    };

  return (
    <Panel p={2} mb={2}>
      <Grid gridTemplateColumns={["1fr", "1fr 1fr 1fr"]} gridGap={2}>
        <Select
          options={diameterOptions}
          value={diameter.toString()}
          onChange={(ev) => setDiameter(ev.target.value)}
        />
        <Select
          options={sizeOptions}
          value={size.toString()}
          onChange={(ev) => setSize(ev.target.value)}
        />
        <Select
          options={appOptions}
          value={category.toString()}
          onChange={change("category")}
        />
      </Grid>
    </Panel>
  );
};
