import { formatSpecialtyFitment, SpecialtyTyreDto } from "@oaktyres/model";
import {
  useAccountNew,
  useProductCategories,
  useSpecialtyFitments,
  useSpecialtyTyres,
  useSupplyOptions,
} from "@oaktyres/queries";
import {
  Box,
  Breadcrumb,
  Breadcrumbs,
  buildSpecialtyFilterSetFromData,
  Circle,
  FilterSidebar,
  Flex,
  Grid,
  Loader,
  MinimumQtyFilter,
  MiniToggle,
  Panel,
  Select,
  Text,
  TyreFilters,
  TyreModal,
} from "@oaktyres/ui";
import { match } from "assert";
import { sortBy, sum } from "lodash";
import React, { useMemo, useState } from "react";
import { FaQuestion } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import { useScopedBasket } from "../../basket";
import { useRetailView } from "../../components/RetailViewProvider";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { ProductCategoryDisplay } from "../Store/ProductCategoryList";
import { useTyreShopSettings } from "../TyreStore/VatModal";
import {
  SpecialtyFitmentQuery,
  SpecialtyFitmentQueryPanel,
} from "./SpecialtyFitmentQueryPanel";
import { SpecialtyTyreCard } from "./SpecialtyTyreCard";

const NoCategories = () => (
  <Flex
    alignItems="center"
    justifyContent={"center"}
    p={5}
    flexDirection="column"
  >
    <Circle color="grey3" icon={FaQuestion} size="xlarge" mb={2} />
    <Text color="grey3" fontWeight={600} fontSize={4}>
      No Categories To Show
    </Text>
  </Flex>
);

export const SpecialtyTyreStorePage = () => {
  const history = useHistory();
  const { categoryId } = useParams<{
    categoryId?: string;
  }>();

  const fitments = useSpecialtyFitments();
  const [shownProduct, setShownProduct] = useState<string | null>(null);
  const [diameter, setDiameter] = useState<string>("");
  const [size, setSize] = useState<string>("");
  const [accountCode] = useScopedAccount();
  const [settings] = useTyreShopSettings();
  const [supply, setSupply] = useState(
    settings.defaultSupply[accountCode] ?? "delivery",
  );

  const [retailView, setRetailView] = useRetailView();
  const account = useAccountNew(accountCode);
  const categories = useProductCategories();
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const activeCategory = (categories.data ?? []).find(
    (x) => x.id === categoryId,
  );
  const basket = useScopedBasket();
  const [minQty, setMinQty] = useState(1);

  const hasActiveQuery = categoryId != null || diameter !== "" || size !== "";
  const supplyOptions = useSupplyOptions(accountCode);

  const collection = supply !== "delivery";

  const setCollection = (val: boolean) => {
    setSupply(
      val
        ? supplyOptions.options.find((x) => x.value !== "delivery")!.value
        : "delivery",
    );
  };

  const tyres = useSpecialtyTyres(
    {
      accountCode,
      category: activeCategory?.id,
    },
    hasActiveQuery,
  );

  const showProduct = (product: SpecialtyTyreDto) => {
    setShownProduct(product.stockCode);
  };

  const shownCategories = sortBy(
    (categories.data ?? []).filter((x) => x.store === "specialty"),
    (x) => x.name,
  ).filter((x) => x.isActive);

  let breadCrumbs: Breadcrumb[] = [
    {
      url: `/store/specialty`,
      label: "Specialty Tyres",
    },
  ];

  if (activeCategory) {
    breadCrumbs.push({
      url: `/store/specialty/${activeCategory.id}`,
      label: activeCategory.name,
    });
  }

  if (diameter) {
    breadCrumbs.push({
      label: `${diameter}"`,
    });
  }

  if (size) {
    const activeFitment = fitments.data?.find((x) => x.id === size);

    if (activeFitment) {
      breadCrumbs.push({
        label: formatSpecialtyFitment(activeFitment),
      });
    }
  }

  const fitmentQuery = {
    diameter,
    size,
    category: categoryId ?? "",
  };

  const onFitmentQueryChange = (newValue: SpecialtyFitmentQuery) => {
    setDiameter(newValue.diameter);
    setSize(newValue.size);
    history.replace(`/store/specialty/${newValue.category}`);
  };

  const matchingFitments = useMemo(() => {
    if (fitments.data == null) {
      return [];
    }

    return fitments.data
      .filter((x) => diameter === "" || x.rimDiameter.toString() === diameter)
      .filter((x) => size === "" || x.id === size)
      .flatMap((x) => [x.id, ...x.aliases]);
  }, [diameter, size, fitments.data]);

  const deliverySwitch =
    supplyOptions.options.length === 2 &&
    supplyOptions.options.some((x) => x.value === "delivery");

  const multipleSupply = supplyOptions.options.length > 1;

  const data = useMemo(
    () =>
      (tyres.data ?? [])
        .filter((x) => matchingFitments.includes(x.fitment?.id ?? ""))
        .sort((a, b) => a.popularity - b.popularity)
        .reverse(),
    [matchingFitments, tyres.data],
  );

  const filters = useMemo(
    () =>
      buildSpecialtyFilterSetFromData(
        data,
        supply !== "delivery" ? "collection" : supply,
      ),
    [data, supply === "delivery"],
  );

  const filteredData = useMemo(() => {
    const totalStock = (tyre: SpecialtyTyreDto) =>
      supply === "delivery"
        ? sum(tyre.availability.map((x) => x.qtyAvailable))
        : sum(
            tyre.collectionAvailability
              .filter((x) => x.source.id === supply)
              .map((x) => x.qtyAvailable),
          );

    const filterFunctions = activeFilters.map(
      (x) => filters[x.split(".")[0] || ""][x.split(".")[1]],
    );

    return data
      .filter((x) => filterFunctions.every((fn) => fn(x, minQty)))
      .filter((x) => totalStock(x) >= minQty);
  }, [filters, activeFilters, data, supply, minQty]);

  return (
    <Box width="100%">
      {shownProduct && (
        <TyreModal
          accountCode={accountCode}
          onClose={() => setShownProduct(null)}
          stockCode={shownProduct}
          defaultSupply={supply}
          basketItems={basket.items}
          onAddToBasket={(qty, supply) =>
            basket.addToBasket(shownProduct, qty, supply)
          }
        />
      )}
      <Panel p={2} pl={[2, 2, 4]} mb={2}>
        <Flex
          justifyContent={"space-between"}
          alignItems={["stretch", "stretch", "center"]}
          flexDirection={["column", "column", "row"]}
        >
          <Box flex={1} mb={[1, 1, 0]}>
            <Breadcrumbs items={breadCrumbs} />
          </Box>
          <Flex>
            {deliverySwitch ? (
              <MiniToggle
                value={collection}
                label="Delivery"
                rightLabel="Collection"
                onChange={setCollection}
                mb={[2, 0]}
                mr={[0, 2]}
              />
            ) : multipleSupply ? (
              <Select
                options={supplyOptions.options}
                onChange={(ev) => setSupply(ev.target.value)}
                value={supply}
                minWidth={0}
                width={["100%", "220px"]}
                mb={[2, 0]}
                mr={[0, 2]}
              />
            ) : null}
            <MiniToggle
              label="Retail View"
              value={retailView}
              onChange={setRetailView}
            />
          </Flex>
        </Flex>
      </Panel>
      <SpecialtyFitmentQueryPanel
        value={fitmentQuery}
        onChange={onFitmentQueryChange}
      />

      {hasActiveQuery ? (
        <Flex alignItems={"flex-start"}>
          <FilterSidebar>
            <Flex flexDirection={"column"} p={3}>
              <MinimumQtyFilter value={minQty} onChange={setMinQty} />
              <TyreFilters
                filters={filters}
                minQty={minQty}
                data={filteredData}
                value={activeFilters}
                onChange={setActiveFilters}
              />
            </Flex>
          </FilterSidebar>
          <Grid
            gridTemplateColumns="repeat(auto-fill, minmax(240px, 1fr))"
            gridGap={2}
            key="products"
            ml={[0, 0, 0, 2]}
            style={{ flex: "1" }}
          >
            {filteredData.map((x) => (
              <SpecialtyTyreCard
                product={x}
                onClick={() => showProduct(x)}
                supply={supply}
                key={x.id}
              />
            ))}
          </Grid>
        </Flex>
      ) : account.isLoading || categories.isLoading ? (
        <Loader />
      ) : shownCategories.length === 0 ? (
        <NoCategories />
      ) : (
        <>
          <Grid
            gridTemplateColumns="repeat(auto-fill, minmax(240px, 1fr))"
            gridGap={2}
            key="categories"
            mb={5}
          >
            {shownCategories.map((x) => (
              <ProductCategoryDisplay
                key={x.id}
                item={x}
                onClick={() => history.push(`/store/specialty/${x.id}`)}
              />
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};
